import { Injectable } from '@angular/core';
import { catchError, mergeMap, of, map } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as MemberActivitiesActions from './member-activities.actions';
import { ActivitiesService } from '@services/activities/activities.service';

@Injectable()
export class MemberActivitiesEffects {
  setMemberActivitiesEnabled = createEffect(() =>
    this.actions.pipe(
      ofType(MemberActivitiesActions.setMemberActivitiesEnabled),
      mergeMap((action) => {
        if (action.enabled) {
          return of(MemberActivitiesActions.loadMemberActivities());
        } else {
          return of();
        }
      })
    )
  );

  loadMemberActivities = createEffect(() =>
    this.actions.pipe(
      ofType(MemberActivitiesActions.loadMemberActivities),
      mergeMap(() =>
        this.activitiesService.getActivities().pipe(
          map((memberActivities) =>
            MemberActivitiesActions.loadMemberActivitiesSuccess({
              memberActivities,
            })
          ),
          catchError((error) => {
            return of(
              MemberActivitiesActions.loadMemberActivitiesFailure({ error })
            );
          })
        )
      )
    )
  );

  loadActivityDetails = createEffect(() =>
    this.actions.pipe(
      ofType(MemberActivitiesActions.loadActivityDetails),
      mergeMap((action) =>
        this.activitiesService.getActivityDetails(action.activityId).pipe(
          map((activityDetails) =>
            MemberActivitiesActions.loadActivityDetailsSuccess({
              activityDetails,
            })
          ),
          catchError((error) =>
            of(MemberActivitiesActions.loadActivityDetailsFailure({ error }))
          )
        )
      )
    )
  );

  submitActivityStep = createEffect(() =>
    this.actions.pipe(
      ofType(MemberActivitiesActions.submitActivityStep),
      mergeMap((action) => {
        const patch =
          action.submitType === 'nba'
            ? this.activitiesService.patchNbaStep(
                action.nbaId,
                action.formAnswers
              )
            : this.activitiesService.patchAttestationStep(
                action.activityId,
                action.formAnswers
              );

        return patch.pipe(
          mergeMap(() =>
            // After PATCH, reload activity details, set success
            this.activitiesService.getActivityDetails(action.activityId).pipe(
              mergeMap((activityDetails) => [
                MemberActivitiesActions.loadActivityDetailsSuccess({
                  activityDetails,
                }),
                MemberActivitiesActions.submitActivityStepSuccess(),
              ]),
              catchError((error) =>
                of(
                  MemberActivitiesActions.loadActivityDetailsFailure({
                    error,
                  })
                )
              )
            )
          ),
          catchError((error) =>
            of(MemberActivitiesActions.submitActivityStepFailure({ error }))
          )
        );
      })
    )
  );

  constructor(
    private actions: Actions,
    private activitiesService: ActivitiesService
  ) {}
}
