export interface MemberActivitiesState {
  memberActivities: {
    active: any[] | null;
    done: any[] | null;
  };
  activityDetails: any | null;
  activeCount: number;
  error: any | null;
  loadDetailsError: any | null;
  enabled: boolean;
  suppressIllustrations: boolean;
  loading: boolean;
  submitting: boolean;
  submitSuccess: boolean;
  submitError: any | null;
  activityModified: boolean;
}

export const initialState: MemberActivitiesState = {
  memberActivities: {
    active: null,
    done: null,
  },
  activityDetails: null,
  activeCount: 0,
  error: null,
  loadDetailsError: null,
  enabled: false,
  suppressIllustrations: false,
  loading: false,
  submitting: false,
  submitSuccess: false,
  submitError: null,
  activityModified: false,
};
